<template>
    <div class="head-container">
        <div class="ellipse"></div>
        <div class="head-content">
            <MainHeadTitle/>
            <MainHeadText/>
        </div>
    </div>
</template>

<script>
    import MainHeadTitle from "@/components/MainHeadTitle";
    import MainHeadText from "@/components/MainHeadText";

    export default {
        name: "MainHead",
        components: {MainHeadText, MainHeadTitle}
    }
</script>

<style lang="scss">
    .head-container {
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 0 0 40px 0;
    }

    .head-content {
        margin: 200px 0 0 0;
    }

    .ellipse {
        position: absolute;
        top: -11px;
        left: -11px;
        width: calc(100vw - 20px);
        height: calc(100vw - 20px);
        z-index: 5;
        border-top: 1px dashed $colorBlack;
        border-left: 1px dashed $colorBlack;
        border-top-left-radius: 100%;
        pointer-events: none;
    }

    @media (min-width: 445px) {
        .ellipse {
            opacity: 0;
        }
    }
</style>