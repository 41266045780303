<template>
    <div class="personality-wrapper">
        <div class="personality-image-wrapper" :style="{ backgroundImage: `url(${ person.image })` }"></div>
        <div class="personality-info-wrapper">
            <h3 class="personality-title">{{person.title}}</h3>
            <span class="date-or-life">{{person.sub_title}}</span>
            <p class="personality-info">
                {{person.text}}
            </p>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "ArticlePersonality",
        props: ['person'],
        computed: {
            ...mapGetters({lang: 'getLang'})
        }
    }
</script>

<style lang="scss">
    .personality-wrapper {
        display: flex;
        flex-direction: column;
        align-items: start;
        border-top: 1px dashed $colorBlack;
        padding: 20px 0 0 0;
        margin: 0 0 20px 0;
    }

    .personality-image-wrapper {
        min-width: 153px;
        min-height: 193px;
        margin: 0 0 20px 0;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .personality-info-wrapper {
        display: flex;
        flex-direction: column;
    }

    .personality-title,
    .date-or-life {
        font-family: $jetBrainsMono;
        font-size: $headText;
        font-weight: $regularWeight;
        line-height: $personalityLineHeight;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin: 0 0 20px 0;
    }

    .personality-info {
        font-family: $jetBrainsMono;
        font-size: $headText;
        font-weight: $regularWeight;
        line-height: 21px;
        text-transform: uppercase;
        letter-spacing: 0.055em;
        margin: 0 0 20px 0;
    }
</style>