<template>
    <div class="container">
        <h1 class="not-found-title title">Not Found page</h1>
    </div>
</template>

<script>
    export default {
        name: "NotFoundPage"
    }
</script>

<style lang="scss">
.not-found-title {
    margin: 40px auto;
}
</style>