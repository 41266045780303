<template>
    <div class="head-skeleton-container">
        <span class="head-skeleton-anchor-link skeleton"></span>
        <div class="head-content-skeleton">
            <span class="head-skeleton-title skeleton"></span>
            <span class="head-skeleton-title skeleton"></span>
            <div class="head-skeleton-box-text">
                <span class="head-skeleton-text skeleton"></span>
                <span class="head-skeleton-text skeleton"></span>
                <span class="head-skeleton-text skeleton"></span>
                <span class="head-skeleton-text skeleton"></span>
                <span class="head-skeleton-text skeleton"></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MainSkeletonHead"
    }
</script>

<style lang="scss">
    .head-skeleton-container {
        display: flex;
        flex-direction: column;
        align-items: end;
        position: relative;
        margin: 0 0 60px 0;
    }

    .head-skeleton-anchor-link {
        width: 40px;
        height: 40px;
        background-color: $skeletonColor;
        margin: 0 0 145px 0;
    }

    .head-content-skeleton {
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    .head-skeleton-title {
        width: 272px;
        height: 40px;
        background-color: $skeletonColor;
        margin: 0 0 20px 0;
    }

    .head-skeleton-box-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .head-skeleton-text {
        width: 333px;
        height: 15px;
        background-color: $skeletonColor;
        margin: 0 0 10px 0;
    }
</style>