<template>
    <div class="article-skeleton-container-main">
        <div class="article-title-skeleton skeleton"></div>
        <div class="article-sub-title-skeleton skeleton"></div>
        <div class="article-text-skeleton skeleton"></div>
        <div class="article-text-skeleton skeleton"></div>
        <div class="article-text-skeleton skeleton"></div>
        <div class="article-text-skeleton skeleton"></div>
        <div class="article-text-skeleton skeleton"></div>
        <div class="article-text-skeleton skeleton"></div>
        <div class="article-text-skeleton skeleton"></div>
        <div class="article-text-skeleton skeleton"></div>
        <div class="article-text-skeleton skeleton"></div>
    </div>
</template>

<script>
    export default {
        name: "ArticleMainSkeleton"
    }
</script>

<style lang="scss">
    .article-skeleton-container-main {
        display: flex;
        flex-direction: column;
        align-items: end;
        margin: 0 10px 0 10px;
    }

    .article-title-skeleton {
        width: 283px;
        height: 40px;
        background: $skeletonColor;
        margin: 0 0 20px 0;
    }

    .article-sub-title-skeleton {
        width: 283px;
        height: 15px;
        background: $skeletonColor;
        margin: 0 0 40px 0;
    }

    .article-text-skeleton {
        width: 100%;
        height: 15px;
        background: $skeletonColor;
        margin: 0 0 10px 0;
    }
</style>