<template>
    <div class="text-wrapper">
        <p class="text">{{ getStaticText[lang].mainPage.description }}</p>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import STATIC_TEXT from "@/constants/staticText";

    export default {
        name: "MainHeadText",
        computed: {
            ...mapGetters({lang: 'getLang'}),
            getStaticText() {
                return STATIC_TEXT;
            }
        },
    }
</script>

<style lang="scss">
    .text {
        font-size: $headText;
        font-weight: $regularWeight;
        line-height: $headTextLineHeight;
        color: $colorBlack;
    }
</style>