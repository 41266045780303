<template>
    <div class="grid-item">
        <router-link :to="{ name: `person`, params: {slug: person} }" class="grid-link-wrapper">
            <div class="img-wrapper" :style="{ backgroundImage:  `url(${articlesImage})`}"></div>
            <div class="grid-text-wrapper">
                <span class="grid-article-description" v-if="subTitle && subTitle.length > 0">{{ subTitle[0].title[lang] }}</span>
                <h2 class="title grid-article-title">{{ articlesTitle[lang] }}</h2>
            </div>
        </router-link>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        props: ['articlesTitle', 'articlesImage', 'subTitle', 'person'],
        computed: {
            ...mapGetters({lang: 'getLang'})
        },
        name: "MainGridItem",
    }
</script>

<style lang="scss">
    .grid-item {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        border-top: 1px dashed $colorBlack;
        padding: 20px 0 0 0;
        margin: 0 0 20px 0;
    }

    .img-wrapper {
        min-height: 228px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 0 10px 0;
    }

    .grid-link-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: $colorBlack;
    }

    .grid-text-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    
    .grid-text-wrapper:after {
        content: '';
        position: absolute;
        right: 5px;
        top: 20px;
        padding: 10px;
        background-image: url("../img/arrow.png");
        background-repeat: no-repeat;
        background-position-y: center;
    }

    .grid-article-description {
        color: $colorBlack;
        font-size: $articleDescription;
        font-weight: $articleDescriptionWeight;
        line-height: $articleDescriptionLineHeight;
        text-transform: uppercase;
        letter-spacing: 0.215em;
        opacity: 0.6;
    }

    .grid-article-title {
        font-size: $articleTitle;
        font-weight: $boldWeight;
        line-height: $articleTitleLineHeight;
    }

    @media (max-width: 400px) {
        .grid-article-title {
            font-size: 19px;
        }
    }
</style>