<template>
    <div class="banner-wrapper" :style="{ backgroundImage: `url(${ image })` }"></div>
</template>

<script>
    export default {
        name: "ArticleBanner",
        props: ['image'],
    }
</script>

<style lang="scss">
    .banner-wrapper {
        width: 100%;
        min-height: 487px;
        margin: 0 0 30px 0;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
</style>