<template>
    <div class="gallery-wrapper">
        <div class="gallery" v-viewer="options">
            <img :src="image" class="gallery-image">
        </div>
    </div>
</template>

<script>
    import "viewerjs/dist/viewer.css";
    import VueViewer from "v-viewer";
    import Vue from "vue";

    Vue.use(VueViewer)

    export default {
        data() {
            return {
                options: {
                    navbar: false,
                    title: false,
                    toolbar: false,
                    tooltip: false,
                    movable: false,
                    zoomable: false,
                    rotatable: false,
                    scalable: false,
                    transition: false,
                    fullscreen: true,
                    keyboard: false,
                }
            }
        },
        name: "ArticleGallery",
        props: ['image'],
    }
</script>

<style lang="scss">
    .gallery-wrapper {
        min-height: 128px;
        margin: 0 0 40px 0;
    }

    .gallery-image {
        display: block;
        width: 100%;
    }
</style>