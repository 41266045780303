<template>
    <button class="link-wrapper" @click="scrollToTop">{{ getStaticText[lang].toTopButton }}</button>
</template>

<script>
    import gsap from "gsap";
    import ScrollToPlugin from "gsap/ScrollToPlugin";
    import {mapGetters} from "vuex";
    import STATIC_TEXT from "@/constants/staticText.js";

    gsap.registerPlugin(ScrollToPlugin);

    export default {
        name: "MainButtonScrollTop",
        methods: {
            scrollToTop() {
                gsap.to(window, {duration: 0.75, scrollTo: {y: 0}});
            },
        },
        computed: {
            ...mapGetters({lang: 'getLang'}),
            getStaticText() {
                return STATIC_TEXT
            },
        },
    }
</script>

<style lang="scss">
    .link-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $anchorLinkSize;
        font-weight: $anchorLinkWeight;
        line-height: $anchorLinkLineHeight;
        letter-spacing: 1px;
        text-decoration: none;
        color: $colorBlack;
        padding: 80px 0 50px 0;
        border-top: 1px dashed $colorBlack;
        outline: none;
        background: none;
        border: none;
    }
</style>