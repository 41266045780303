<template>
    <div class="person-container">
        <template>
            <ArticlePageSkeleton v-if="statusLoading"/>
            <div class="article-content-container" v-else>
                <ReturnMainButton/>
                <ArticleBanner :image="person.image"/>
                <ArticleMain :content="person.content[lang]" :title="person.title[lang]" :subTitle="person.sub_title[lang]"
                             :nextPerson="person.next"/>
            </div>
        </template>
    </div>
</template>

<script>
    import ArticleBanner from "@/components/ArticleBanner";
    import ArticleMain from "@/components/ArticleMain";
    import {mapActions, mapGetters} from "vuex";
    import ReturnMainButton from "@/components/ReturnMainButton";
    import ArticlePageSkeleton from "@/components/ArticlePageSkeleton";

    export default {
        name: "Person",
        components: {ArticlePageSkeleton, ReturnMainButton, ArticleMain, ArticleBanner},
        methods: {
            ...mapActions(['getPersonArticle']),
        },
        computed: {
            getPersonSlug() {
                return this.$route.params.slug;
            },
            ...mapGetters({person: 'getPerson', lang: 'getLang', statusLoading: 'getStatusLoadingPerson'})
        },
        mounted() {
            this.getPersonArticle(this.getPersonSlug);
        }
    }
</script>

<style lang="scss">

</style>