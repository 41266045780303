<template>
    <div class="main-skeleton-container">
        <MainGridSkeleton>
            <MainGridItemSkeleton v-for="({ id }) in count" :key="id"/>
        </MainGridSkeleton>
    </div>
</template>

<script>
    import MainGridSkeleton from "./MainGridSkeleton";
    import MainGridItemSkeleton from "./MainGridItemSkeleton";

    export default {
        data() {
            return {
                count: 3,
            }
        },
        name: "MainSkeleton",
        components: {MainGridItemSkeleton, MainGridSkeleton}
    }
</script>

<style lang="scss">
    .skeleton {
        animation: skeleton-loading 1s infinite alternate;
    }

    @keyframes skeleton-loading {
        0% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
</style>