<template>
    <div class="title-wrapper">
        <h1 class="title title-level-1" v-html="getStaticText[lang].mainPage.title">{{getStaticText[lang].mainPage.title}}</h1>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import STATIC_TEXT from "@/constants/staticText";

    export default {
        name: "Title",
        computed: {
            ...mapGetters({lang: 'getLang'}),
            getStaticText() {
                return STATIC_TEXT;
            }
        },
    }
</script>

<style lang="scss">
    .title {
        font-weight: $boldWeight;
        color: $colorBlack;
        letter-spacing: -0.03em;
    }

    .title-level-1 {
        font-size: $headTitle;
        line-height: $headTitleLineHeight;
        text-align: end;
        margin: 0 0 20px 0;
        padding: 0 10px 0 0;
    }
</style>