<template>
    <div class="video-wrapper">
        <video-player class="video-player-box" ref="videoPlayer" :options="playerOptions" :playsinline="true" customEventName="customstatechangedeventname">

        </video-player>
    </div>
</template>

<script>
    import { videoPlayer } from 'vue-video-player';
    import 'video.js/dist/video-js.css';

    export default {
        data() {
            return {
                playerOptions: {
                  language: 'en',
                  playbackRates: [0.7, 1.0, 1.5, 2.0],
                  sources: [{
                     type: "video/mp4",
                      src: this.video,
                  }],
                },
            }
        },
        name: "ArticleVideo",
        props: ['video'],
        components: {
          videoPlayer
        },
    }
</script>

<style lang="scss">
.video-js {
    width: 100%;
    max-height: 181px;
    margin: 0 0 20px 0;
    backdrop-filter: blur(4px);
}
.vjs-big-play-button {
    width: 2em !important;
    height: 2em !important;
    font-size: 2em !important;
    top: calc(50% - 20px) !important;
    left: calc(50% - 20px) !important;
    border-radius: 50% !important;
}
.vjs-icon-placeholder:before {
    top: 4px !important;
}
</style>