<template>
    <button class="multi-language-button" @click="clickChange">{{ lang === 'ru' ? 'en': 'ru' }}</button>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex';

    export default {
        name: "MultiLanguageButton",
        computed: {
            ...mapGetters({lang: 'getLang'})
        },
        methods: {
            ...mapMutations({setLang: 'setLanguage'}),
            clickChange() {
                this.setLang(this.lang === 'ru' ? 'en' : 'ru');
            },
        },
    }
</script>

<style lang="scss">
    .multi-language-button {
        width: 39px;
        height: 39px;
        position: absolute;
        border: 1px solid $colorBlack;
        background-image: url("../img/backgorundAnchorLink.png");
        background-repeat: no-repeat;
        background-position: center;
        z-index: 10;
        font-size: $headText;
        font-weight: $anchorLinkWeight;
        text-transform: uppercase;
        outline: none;
        color: $colorBlack;
    }
</style>