const STATIC_TEXT = {
    ru: {
        toTopButton: 'Ввернуться наверх',
        returnButton: 'В галерею',
        furtherButton: 'далее:',
        mainPage: {
            title: 'Полет<br/>в искусстве',
            description: 'Воздухоплавание поспособствовало научным исследованием: человеку удалось подняться на существенную высоту и увидеть Землю сверху. Появились новые подходы к измерению и пониманию погоды, картографированию, исследованию и пониманию географических границ.'
        }
    }, en: {
        toTopButton: 'Scroll Top',
        returnButton: 'To gallery',
        furtherButton: 'next:',
        mainPage: {
            title: 'The flying<br/>in art',
            description: 'Art and flight Aeronautics contributed to scientific research: a person managed to climb to a significant height and see the Earth from above. New approaches have emerged towards measuring and understanding the weather, towards mapping, exploration and understanding of geographical boundaries'
        }
    }
};
export default STATIC_TEXT;
