import Vue from "vue";
import VueRouter from "vue-router";
import Main from "@/pages/Main";
import ArticlePage from "@/pages/ArticlePage";
import Person from '@/components/Person';
import NotFoundPage from "@/pages/NotFoundPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: Main,
  },
  {
    path: "/article",
    name: "article",
    component: ArticlePage,
    children: [
      {
        path: ":slug",
        name: "person",
        component: Person
      }
    ]
  },
  {
    name: 'notFound',
    component: NotFoundPage,
    path: '*',
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
