<template>
    <div class="article-title-wrapper">
        <h1 class="title article-title">{{ title }}</h1>
        <span class="years-description">{{ subTitle }}</span>
    </div>
</template>

<script>
    export default {
        name: "ArticleTitle",
        props: ['title', 'subTitle']
    }
</script>

<style lang="scss" scoped>
    .article-title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: end;
        margin: 0 0 40px 0;
    }

    .article-title {
        font-size: $headTitle;
        font-weight: $boldWeight;
        text-align: right;
        line-height: 52.8px;
        margin: 0 0 10px 0;
    }

    .years-description {
        display: block;
        width: 100%;
        font-size: $articleTitle;
        font-weight: $regularWeight;
        text-align: right;
        line-height: $anchorLinkLineHeight;
    }

    @media (max-width: 470px) {
        .article-title {
            font-size: 35px;
            font-weight: 600;
            text-align: right;
            line-height: 44.8px;
            margin: 0 0 10px 0;
        }
    }
</style>