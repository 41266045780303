<template>
    <div class="grid-skeleton-item">
        <span class="grid-image-skeleton skeleton"></span>
        <div class="grid-skeleton-content">
            <div class="grid-skeleton-text">
                <span class="grid-skeleton-description skeleton"></span>
                <span class="grid-skeleton-title skeleton"></span>
            </div>
            <span class="grid-skeleton-arrow skeleton"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MainGridItemSkeleton"
    }
</script>

<style lang="scss">
    .grid-skeleton-item {
        margin: 40px 0;
    }

    .grid-image-skeleton {
        display: block;
        height: 228px;
        background-color: $skeletonColor;
        margin: 0 0 10px 0;
    }

    .grid-skeleton-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .grid-skeleton-text {
        display: flex;
        flex-direction: column;
    }

    .grid-skeleton-description {
        width: 126px;
        height: 15px;
        background-color: $skeletonColor;
        margin: 0 0 10px 0;
    }

    .grid-skeleton-title {
        width: 126px;
        height: 40px;
        background-color: $skeletonColor;
    }

    .grid-skeleton-arrow {
        display: block;
        width: 30px;
        height: 15px;
        background-color: $skeletonColor;
        margin: 55px 0 0 0;
    }
</style>