<template>
    <div class="article-main">
        <ArticleTitle :title="title" :subTitle="subTitle"/>
        <ArticleLayout v-for="({ layout, attributes, id }) in content" :key="id">
            <ArticleText :text="attributes.text" v-if="layout === layoutType.text"/>
            <ArticlePersonality :person="attributes" v-if="layout === layoutType.person"/>
            <template v-if="layout === layoutType.image">
                <ArticleGallery :image="attributes.image"/>
            </template>
            <ArticleQuotes :quote="attributes.text" :author="attributes.author" v-if="layout === layoutType.quote"/>
            <ArticleNote :title="attributes.title"  :text="attributes.text" v-if="layout === layoutType.note"/>
            <ArticleVideo :video="attributes.file" v-if="layout === layoutType.video"/>
        </ArticleLayout>
        <ArticleButtonFurther :next="nextPerson"/>
    </div>
</template>

<script>
    import ArticleGallery from "@/components/ArticleGallery";
    import ArticleQuotes from "@/components/ArticleQuotes";
    import ArticleText from "@/components/ArticleText";
    import ArticleTitle from "@/components/ArticleTitle";
    import ArticlePersonality from "@/components/ArticlePersonality";
    import ArticleLayout from "@/components/ArticleLayout";
    import ArticleButtonFurther from "@/components/ArticleButtonFurther";
    import ArticleVideo from "@/components/ArticleVideo";
    import ArticleNote from "@/components/ArticleNote";

    export default {
        data() {
            return {
                layoutType: {
                    text: 'text',
                    image: 'image',
                    quote: 'quote',
                    video: 'video',
                    person: 'person',
                    note: 'note',
                },
            }
        },
        name: "ArticleMain",
        props: ['title', 'subTitle', 'content', 'nextPerson'],
        components: {
            ArticleNote,
            ArticleVideo,
            ArticleButtonFurther,
            ArticleLayout, ArticlePersonality, ArticleTitle, ArticleText, ArticleQuotes, ArticleGallery
        },
    }
</script>

<style lang="scss">
    .article-main {
        padding: 0 10px;
    }
</style>