<template>
    <div class="article-skeleton-container">
        <ArticleSkeletonHead/>
        <ArticleMainSkeleton/>
    </div>
</template>

<script>
    import ArticleSkeletonHead from "@/components/ArticleSkeletonHead";
    import ArticleMainSkeleton from "@/components/ArticleMainSkeleton";

    export default {
        name: "ArticlePageSkeleton",
        components: {ArticleMainSkeleton, ArticleSkeletonHead}
    }
</script>

<style lang="scss">
    .article-skeleton-container {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .skeleton {
        animation: skeleton-loading 1s infinite alternate;
    }

    @keyframes skeleton-loading {
        0% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
</style>