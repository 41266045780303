<template>
    <router-link :to="{ name: `person`, params: { slug: next.slug }}" class="link-wrapper">
        <span class="link-text"><span class="link-text">{{getStaticText[lang].furtherButton}}</span>{{ next.title[lang] }}</span>
        <span class="arrow"></span>
    </router-link>
</template>

<script>
    import {mapGetters} from "vuex";
    import STATIC_TEXT from "@/constants/staticText";

    export default {
        name: "ArticleButtonFurther",
        props: ['next'],
        computed: {
            ...mapGetters({lang: 'getLang'}),
            getStaticText() {
                return STATIC_TEXT
            },
        },
    }
</script>

<style lang="scss">
    .link-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $anchorLinkSize;
        font-weight: $anchorLinkWeight;
        line-height: $anchorLinkLineHeight;
        letter-spacing: 1px;
        padding: 80px 0 50px 0;
        border-top: 1px dashed $colorBlack;
    }

    .link-text {
        font-size: $anchorLinkSize;
        font-weight: $anchorLinkWeight;
        line-height: $authorLineHeight;
        margin: 0 10px 0 0;
        text-align: center;
    }

    .arrow {
        background-image: url("../img/arrow.png");
        background-repeat: no-repeat;
        background-position-y: center;
        width: 20px;
        height: 20px;
    }
</style>