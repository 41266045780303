<template>
    <div class="quotes-wrapper">
        <p class="quotes-text">
            {{ quote }}
        </p>
        <span class="author-quote">{{ author }}</span>
    </div>
</template>

<script>
    export default {
        name: "ArticleQuotes",
        props: ['quote', 'author'],
    }
</script>

<style lang="scss">
    .quotes-wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px 0 0 0;
        margin: 0 0 50px 0;
    }

    .quotes-text {
        font-family: $jetBrainsMono;
        font-size: $headText;
        font-weight: $articleDescriptionWeight;
        line-height: $anchorLinkLineHeight;
        letter-spacing: 0.05em;
        margin: 0 0 20px 0;
    }

    .author-quote {
        display: block;
        font-size: $headText;
        font-weight: $regularWeight;
        line-height: $authorLineHeight;
        text-align: end;
    }
</style>