<template>
    <main class="main">
        <div class="container">
            <MainHead/>
            <MainSkeletonHead v-if="false"/>
            <template>
                <MainSkeleton v-if="articles.length === 0"/>
                <MainSection :articles="articles" v-else/>
            </template>
            <MainButtonScrollTop/>
        </div>
    </main>
</template>

<script>
    import MainHead from "@/components/MainHead";
    import MainSection from "@/components/MainSection";
    import MainButtonScrollTop from "@/components/MainButtonScrollTop";
    import {mapActions, mapGetters} from "vuex";
    import MainSkeleton from "@/components/MainSkeleton";
    import MainSkeletonHead from "@/components/MainSkeletonHead";

    export default {
        name: "Main",
        components: {MainSkeletonHead, MainSkeleton, MainButtonScrollTop, MainHead, MainSection},
        methods: {
            ...mapActions(['getArticles']),
        },
        computed: {
            ...mapGetters({articles: 'getArticles'}),
        },
        created() {
            this.getArticles();
        },
        mounted() {
            window.scrollTo(0, 0)
        }
    }
</script>

<style lang="scss">
    .container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid $colorBlack;
    }

    .multi-language-button {
        top: 10px;
        right: 10px;
    }
</style>