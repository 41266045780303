<template>
    <div class="article-skeleton-container-head">
        <div class="skeleton-return-button skeleton"></div>
        <div class="article-skeleton-banner skeleton"></div>
    </div>
</template>

<script>
    export default {
        name: "ArticleSkeletonHead"
    }
</script>

<style lang="scss">
    .article-skeleton-container-head {
        display: flex;
        position: relative;
        margin: 0 0 20px 0;
    }

    .skeleton-return-button {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 175px;
        height: 40px;
        background: $skeletonArticleButtonColor;
        z-index: 10;
    }

    .article-skeleton-banner {
        width: 100%;
        height: 487px;
        background: $skeletonColor;
    }
</style>