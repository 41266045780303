<template>
    <router-link to="/" class="return-button">
        <span class="return-button-text">{{ getStaticText[lang].returnButton }}</span>
    </router-link>
</template>

<script>
    import {mapGetters} from "vuex";
    import STATIC_TEXT from "@/constants/staticText";

    export default {
        computed: {
            ...mapGetters({lang: 'getLang'}),
            getStaticText() {
                return STATIC_TEXT;
            },
        },
        name: "ReturnMainButton",
    }
</script>

<style lang="scss">
    .return-button {
        position: absolute;
        top: 10px;
        left: 10px;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        background-image: url("../img/bgReturnButton.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 132px;
        padding: 10px 0;
        border: 1px solid $colorBlack;
    }

    .return-button-text {
        display: block;
        position: relative;
        font-size: $headText;
        font-weight: $anchorLinkWeight;
        color: $colorBlack;
        letter-spacing: 0.1em;
        padding: 0 0 0 15px;
    }

    .return-button-text:before {
        position: absolute;
        bottom: -1px;
        left: 5px;
        content: '';
        background-image: url("../img/arr.png");
        background-repeat: no-repeat;
        background-position: left;
        padding: 10px;
    }
</style>