<template>
    <div class="article-text-wrapper">
        <p class="article-text">
            {{ text }}
        </p>
    </div>
</template>

<script>
    export default {
        name: "ArticleText",
        props: ['text'],
    }
</script>

<style lang="scss">
    .article-text {
        font-size: $headText;
        font-weight: $regularWeight;
        line-height: $anchorLinkLineHeight;
        margin: 0 0 40px 0;
    }

    .article-text-span {
        border-bottom: 1px solid $colorBlack;
    }
</style>