import Vue from "vue";
import Vuex from "vuex";
import {API_URL} from "@/constants/api";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        lang: 'ru',
        articleItems: {
            items: [],
            status: null,
            code: null,
            isLoading: true,
        },
        articlePerson: {
            items: [],
            status: null,
            code: null,
            isLoading: true,
        },
    },
    mutations: {
        setArticles(state, {data}) {
            state.articleItems.items = data;
        },
        setPersonArticle(state, {data}) {
            state.articlePerson.items = data;
        },
        setLanguage(state, language) {
            state.lang = language;
        },
        setStatusLoadingPerson(state, status) {
            state.articlePerson.isLoading = status;
        }
    },
    getters: {
        getLang(state) {
            return state.lang
        },
        getArticles(state) {
            return state.articleItems.items;
        },
        getPerson(state) {
            return state.articlePerson.items;
        },
        getStatusLoadingPerson(state) {
          return state.articlePerson.isLoading;
        },
    },
    actions: {
        getArticles(context) {
            fetch(`${API_URL.articles}`)
                .then(response => {
                    return response.json();
                })
                .then(response => {
                    context.commit("setArticles", response);
                });
        },
        getPersonArticle(context, payload) {
            context.commit("setStatusLoadingPerson", true);
            fetch(`${API_URL.articles}/${payload}`)
                .then(response => {
                    return response.json();
                })
                .then(response => {
                    context.commit("setStatusLoadingPerson", false);
                    context.commit("setPersonArticle", response);
                })
        },
    },
});