<template>
    <div id="app">
        <MultiLanguageButton/>
        <router-view :key="$route.fullPath"/>
    </div>
</template>

<style lang="scss">
    @font-face {
        font-family: $golos;
        font-weight: 900;
        src: url("fonts/Golos-Text_Black.ttf");
    }

    @font-face {
        font-family: $golos;
        font-weight: 700;
        src: url("fonts/Golos-Text_Bold.ttf");
    }

    @font-face {
        font-family: $golos;
        font-weight: 600;
        src: url("fonts/Golos-Text_DemiBold.ttf");
    }

    @font-face {
        font-family: $golos;
        font-weight: 500;
        src: url("fonts/Golos-Text_Medium.ttf");
    }

    @font-face {
        font-family: $golos;
        font-weight: 400;
        src: url("fonts/Golos-Text_Regular.ttf");
    }

    @font-face {
        font-family: $jetBrainsMono;
        font-weight: 100;
        src: url("fonts/JetBrainsMono.ttf");
    }

    @font-face {
        font-family: $jetBrainsMono;
        font-weight: 300;
        src: url("fonts/JetBrainsMono-LightItalic.ttf");
    }

    @font-face {
        font-family: $jetBrainsMono;
        font-weight: 400;
        src: url("fonts/JetBrainsMono-Light.ttf");
    }

    * {
        margin: 0;
        padding: 0;
        font-family: $golos;
    }

    body, html {
        width: 100%;
        height: 100%;
        background-image: url("img/polet-background.png");
        background-repeat: repeat-y;
        background-size: cover;
    }

    #app {
        padding: 10px;
    }

    .lg-on {
        overflow: hidden;
    }
</style>
<script>

    import MultiLanguageButton from "./components/MultiLanguageButton";

    export default {
        name: "App",
        components: {MultiLanguageButton},
    }
</script>