<template>
    <div class="note-wrapper">
        <span class="note-title">{{ title }}</span>
        <p class="note-text">{{ text }}</p>
    </div>
</template>

<script>
    export default {
        props: ['title', 'text'],
        name: "ArticleNote"
    }
</script>

<style lang="scss">
    .quotes-wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px 0 0 0;
        margin: 0 0 20px 0;
    }

    .note-title {
        display: block;
        font-family: $jetBrainsMono;
        font-size: $headText;
        font-weight: $lighterWeight;
        line-height: 21px;
        margin: 0 0 30px 0;
    }

    .note-text {
        font-family: $jetBrainsMono;
        font-size: $headText;
        font-weight: $lighterWeight;
        line-height: 21px;
        text-transform: lowercase;
        letter-spacing: 0.05em;
        margin: 0 0 20px 0;
    }


</style>