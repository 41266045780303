<template>
    <div class="grid-wrapper">
        <MainGrid>
            <MainGridItem v-for="({ title, image, id, slug, tags}) in articles" :key="id" :articlesTitle="title" :articlesImage="image" :subTitle="tags" :person="slug"/>
        </MainGrid>
    </div>
</template>

<script>
    import MainGrid from "@/components/MainGrid";
    import MainGridItem from "@/components/MainGridItem";

    export default {
        props: ['articles'],
        name: "MainSection",
        components: {MainGrid, MainGridItem},
    }
</script>

<style lang="scss">
    .grid-wrapper {
        width: 100%;
    }
</style>