<template>
    <div class="container">
        <router-view></router-view>
    </div>
</template>

<script>
    //import {mapActions} from "vuex";

    export default {
        name: "ArticlePage",
        mounted() {
            window.scrollTo(0, 0);

        }
    }
</script>

<style lang="scss" scoped>
    .container {
        display: flex;
        flex-direction: column;
        padding: 0;
        border: 1px solid $colorBlack;
    }
</style>