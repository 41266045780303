<template>
    <div class="article-layout-wrapper">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "ArticleLayout"
    }
</script>

<style scoped>

</style>